/* eslint-disable react-hooks/exhaustive-deps */
/* base */
import React from "react";
import { Module } from "components/module";
import { GraphQLClient, ClientContext } from "graphql-hooks";
import { BrowserRouter, Switch, Route, Link, Redirect } from "react-router-dom";

/* modules */
import { colors } from "theme/colors";
import { HomeModule } from "modules/home";
import { AboutModule } from "modules/about";
import { GalleryModule } from "modules/gallery/experimental";
import { SupportModule } from "modules/support";
import { VolunteerModule } from "modules/volunteer";
import { ParticipateModule } from "modules/participate";
import { ContactModule } from "modules/contact";
import { HeaderComponent } from "components/header";
import { Footer } from "components/footer";
import { Map } from "modules/map";
import { LiveStream } from "modules/live-stream";
// import { BreachFestivalModule } from "modules/org-projects/project-pages/breach";
// import { useFirstLoad } from "hooks/useFirstLoad";
// import { SignUpPerform } from "modules/sign-up";
import Programme from "modules/programme/versions/2023";
import { LineUp } from "modules/line-up";

const client = new GraphQLClient({
  url: "https://graphql.datocms.com/",
  headers: {
    Authorization: "Bearer 8e5496b7fb27e177875fe5ed092e80",
  },
});

const comingsection = (phrase) => (
  <React.Fragment>
    <Module background={colors.orange1}>
      <div
        style={{
          height: "100%",
          display: "flex",
          fontSize: "5em",
          color: "#ffffff",
          padding: "0 10vw",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span style={{ textAlign: "center" }}>{phrase}</span>
      </div>
    </Module>
    <Footer />
  </React.Fragment>
);

const RedirectOut = (props) => {
  React.useEffect(() => {
    window.location = props.url;
  }, []);

  return null;
};

function App() {
  // useFirstLoad();

  return (
    <ClientContext.Provider value={client}>
      <BrowserRouter>
        <HeaderComponent />
        <Switch>
          <Route exact path="/">
            <HomeModule />
          </Route>
          <Route path="/about-festival">
            <AboutModule />
          </Route>
          <Route path="/participate">
            <ParticipateModule />
          </Route>
          <Route path="/volunteer">
            <VolunteerModule />
          </Route>
          <Route path="/support">
            <SupportModule />
          </Route>
          <Route path="/gallery">
            <GalleryModule />
          </Route>
          <Route path="/contact">
            <ContactModule />
          </Route>
          <Route path="/lineup">
            <LineUp />
          </Route>
          <Route path="/lineup-2023">
            <Programme />
            <Footer />
          </Route>
          <Route path="/2023-program">
            <Redirect to="/lineup-2023" />
          </Route>
          <Route path="/map">
            <Map />
            <Footer />
          </Route>
          <Route path="/global-live-stream">
            <LiveStream />
          </Route>
          <Route path="/sign-up/perform">
            {comingsection(
              <>
                THANK YOU FOR YOUR SUBMISSIONS <b>SEE YOU ON JUNE 21!</b>
                <div
                  style={{
                    fontSize: "0.5em",
                    marginTop: "4em",
                  }}
                >
                  IF YOU HAVE ANY QUESTIONS, PLEASE{" "}
                  <Link
                    to="/contact"
                    style={{
                      color: "#000",
                    }}
                  >
                    CONTACT US
                  </Link>
                </div>
              </>
            )}
            {/* <SignUpPerform /> */}
          </Route>
          <Route path="/sign-up/host">
            {comingsection(
              <>
                THE <b>HOST</b> APPLICATION WILL BE AVAILABLE SOON
              </>
            )}
          </Route>
          <Route path="/sign-up/lead">
            {comingsection(
              <>
                THE <b>LEAD</b> APPLICATION WILL BE AVAILABLE SOON
              </>
            )}
          </Route>
          <Route path="/sign-up">
            {comingsection(
              <>
                COMING <b>SOON!</b>
              </>
            )}
          </Route>
          <Route path="/qr-code">
            <Redirect to="/lineup" />
          </Route>
          <Route exact path="/breach-festival">
            {/* <BreachFestivalModule /> */}
            <RedirectOut url="https://www.instagram.com/breach_festival" />
          </Route>
          <Route path="/donate">{comingsection(<>COMING SOON</>)}</Route>
          <Route path="*">
            <Module background={colors.orange1}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  fontSize: "5em",
                  color: "#ffffff",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>
                  <b>OOPS!</b>
                </span>
              </div>
            </Module>
            <Footer />
          </Route>
        </Switch>
      </BrowserRouter>
    </ClientContext.Provider>
  );
}

export default App;
